import { Box, Container, CssBaseline, styled, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

const Main = styled("main")(({ theme }) => ({
  width: "100%",
  flexGrow: 1,
  padding: theme.spacing(2),
  overflow: "auto",

  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2, 0),
  },
}));

const MainLayout = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        background: theme.palette.common.white,
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "space-between",
      }}
    >
      <CssBaseline />

      <Header />

      <Main theme={theme}>
        <Container>
          <Outlet />
        </Container>
      </Main>
      <Footer />
    </Box>
  );
};

export default MainLayout;
