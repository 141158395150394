import { Navigate, createBrowserRouter } from "react-router-dom";
import MainRoutes from "./MainRoutes";

// ==============================|| ROUTING RENDER ||============================== //

const router = createBrowserRouter([
  { path: "*", element: <Navigate to="/" /> },
  MainRoutes,
]);

export default router;
