import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  styled,
  useTheme,
  List,
  ListItem,
  ListItemText,
  Container,
  Grid,
  IconButton,
  Slide,
  Backdrop,
  Stack,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "ui-component/Logo";

const StyledDiv = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(2, 0),
  width: "100%",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  borderBottom: `2px solid ${theme.palette.primary.main}`,
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(1, 0),
  },
}));

const StyledList = styled(List)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  gap: theme.spacing(1),
  padding: 0,
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  width: "auto",
  padding: theme.spacing(0),
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    borderRadius: theme.shape.borderRadius,
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.common.black,
  padding: theme.spacing(1),

  "&:hover": {
    color: theme.palette.primary.main,
  },
  display: "block",
  width: "100%",
}));

const ActiveLink = styled(StyledLink)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  borderRadius: theme.shape.borderRadius,
}));

const DrawerContent = styled(Box)(({ theme }) => ({
  width: "100%",
  position: "fixed",
  bottom: 0,
  left: 0,
  borderTopLeftRadius: theme.spacing(1),
  borderTopRightRadius: theme.spacing(1),
  backgroundColor: theme.palette.common.white,
  zIndex: theme.zIndex.drawer + 1,
  padding: theme.spacing(2),
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  borderBottom: `2px solid ${theme.palette.primary.main}`,
  maxHeight: "70vh",
  overflowY: "auto",
}));

const NavLink = ({ to, children, onClick }) => {
  const location = useLocation();
  const isActive = location.pathname === to;
  const LinkComponent = isActive ? ActiveLink : StyledLink;

  return (
    <LinkComponent to={to} onClick={onClick}>
      <ListItemText primary={children} />
    </LinkComponent>
  );
};

const NavList = ({ toggleDrawer }) => (
  <>
    <StyledListItem>
      <NavLink to="/" onClick={toggleDrawer(false)}>
        Home
      </NavLink>
    </StyledListItem>
    <StyledListItem>
      <NavLink to="/color-shades" onClick={toggleDrawer(false)}>
        Color Shades
      </NavLink>
    </StyledListItem>
    <StyledListItem>
      <NavLink to="/gradient-backgrounds" onClick={toggleDrawer(false)}>
        Backgrounds
      </NavLink>
    </StyledListItem>
    {/* <StyledListItem>
      <NavLink to="/blog" onClick={toggleDrawer(false)}>
        Blog
      </NavLink>
    </StyledListItem> */}
  </>
);

const Header = () => {
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [slideDirection, setSlideDirection] = useState("up");

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (open) {
      setSlideDirection("up");
      document.body.style.overflow = "hidden";
    } else {
      setSlideDirection("up");
      document.body.style.overflow = "auto";
    }
    setDrawerOpen(open);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        drawerOpen &&
        !event.target.closest("#drawer-content") &&
        !event.target.closest("#menu-button")
      ) {
        setSlideDirection("down");
        setDrawerOpen(false);
        document.body.style.overflow = "auto";
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [drawerOpen]);

  return (
    <StyledDiv>
      <Container>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={8} md={4}>
            <Logo />
          </Grid>
          <Grid item xs={2} md={8} container justifyContent="flex-end">
            <StyledList>
              <NavList toggleDrawer={toggleDrawer} />
            </StyledList>
            <MenuButton onClick={toggleDrawer(true)}>
              <MenuIcon />
            </MenuButton>
          </Grid>
        </Grid>
      </Container>
      <Backdrop
        open={drawerOpen}
        onClick={toggleDrawer(false)}
        sx={{ zIndex: theme.zIndex.drawer }}
      />
      <Slide
        direction={slideDirection}
        in={drawerOpen}
        mountOnEnter
        unmountOnExit
      >
        <DrawerContent id="drawer-content">
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h6" gutterBottom>
              Menu
            </Typography>
            <IconButton onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <List>
            <NavList toggleDrawer={toggleDrawer} />
          </List>
        </DrawerContent>
      </Slide>
    </StyledDiv>
  );
};

export default Header;
