import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

const Home = Loadable(lazy(() => import("views/home")));
const ColorShades = Loadable(lazy(() => import("views/color-shades")));
const ShadesOf = Loadable(lazy(() => import("views/shades-of")));
const GradientBackgrounds = Loadable(
  lazy(() => import("views/gradient-backgrounds"))
);

const AboutUs = Loadable(lazy(() => import("views/other/AboutUs")));
const ContactUs = Loadable(lazy(() => import("views/other/ContactUs")));
const PrivacyPolicy = Loadable(lazy(() => import("views/other/PrivacyPolicy")));

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/color-shades",
      element: <ColorShades />,
    },
    {
      path: "shades-of/:color",
      element: <ShadesOf />,
    },
    {
      path: "/gradient-backgrounds",
      element: <GradientBackgrounds />,
    },

    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/about-us",
      element: <AboutUs />,
    },
    {
      path: "/contact-us",
      element: <ContactUs />,
    },
  ],
};

export default MainRoutes;
