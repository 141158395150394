import { createTheme } from "@mui/material/styles";

const Palette = () => {
  return createTheme({
    palette: {
      common: {
        black: "#000000",
        white: "#FFFFFF",
      },
      primary: {
        light: "#FFB380",
        main: "#FF6000", // ffbc00
        dark: "#CC4D00",
      },
      secondary: {
        light: "#66AFFF",
        main: "#0060FF",
        dark: "#0040CC",
      },
      error: {
        light: "#ef9a9a",
        main: "#f44336",
        dark: "#c62828",
      },
      warning: {
        light: "#ffb74d",
        main: "#ff9800",
        dark: "#f57c00",
      },
      success: {
        light: "#81c784",
        200: "#66bb6a",
        main: "#4caf50",
        dark: "#388e3c",
      },
      info: {
        light: "#64b5f6",
        main: "#2196f3",
        dark: "#1976d2",
      },
      grey: {
        50: "#fafafa",
        100: "#f5f5f5",
        500: "#9e9e9e",
        600: "#757575",
        700: "#616161",
        900: "#212121",
        textColor: "#000000",
        hover: "#f4f4f4",
      },
      text: {
        primary: "#212121",
        secondary: "#757575",
        dark: "#000000",
        hint: "#9e9e9e",
        textColor: "#000000",
      },
      divider: "#bdbdbd",
    },
  });
};

export default Palette;
