import PropTypes from "prop-types";
import { useMemo } from "react";

// material-ui
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// project import
import useConfig from "hooks/useConfig";
import Palette from "./palette";
import Typography from "./typography";

import componentStyleOverrides from "./compStyleOverride";

export default function ThemeCustomization({ children }) {
  const { borderRadius, fontFamily, navType, outlinedFilled, rtlLayout } =
    useConfig();

  const theme = useMemo(() => Palette(navType), [navType]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const themeTypography = useMemo(
    () => Typography(theme, borderRadius, fontFamily),
    [theme, borderRadius, fontFamily]
  );

  const themeOptions = useMemo(
    () => ({
      direction: rtlLayout ? "rtl" : "ltr",
      palette: theme.palette,
      mixins: {
        toolbar: {
          minHeight: "48px",
          padding: "16px",
          "@media (min-width: 600px)": {
            minHeight: "48px",
          },
        },
      },
      typography: themeTypography,
    }),
    [rtlLayout, theme, themeTypography]
  );

  const themes = createTheme(themeOptions);
  themes.components = useMemo(
    () => componentStyleOverrides(themes, borderRadius, outlinedFilled),
    [themes, borderRadius, outlinedFilled]
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

ThemeCustomization.propTypes = {
  children: PropTypes.node,
};
