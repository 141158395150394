export const DASHBOARD_PATH = "/";
export const HORIZONTAL_MAX_ITEM = 7;

const config = {
  fontFamily: `'Montserrat', sans-serif`,
  borderRadius: 8,
  container: true,
};

export default config;
