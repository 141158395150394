import { Box, Link, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import Logo from "ui-component/Logo";

const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(6, 0),
  borderTop: `1px solid ${theme.palette.divider}`,
  color: theme.palette.text.primary,
  textAlign: "center",
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: theme.palette.common.black,
  textDecoration: "none",
  margin: theme.spacing(0, 2),
  "&:hover": {
    color: theme.palette.secondary.dark,
    textDecoration: "underline",
  },
}));

const Footer = () => {
  return (
    <FooterContainer>
      <Box mb={3} display="flex" justifyContent="center">
        <Logo />
      </Box>

      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        gap={2}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <FooterLink href="/privacy-policy" variant="f16">
          Privacy Policy
        </FooterLink>
        <FooterLink href="/color-shades" variant="f16">
          Color Shades
        </FooterLink>
        <FooterLink href="/gradient-backgrounds" variant="f16">
          Backgrounds
        </FooterLink>
        {/* <FooterLink href="/blog" variant="f16">
          Blog
        </FooterLink> */}

        {/* <FooterLink href="/about-us" variant="f16">
          About Us
        </FooterLink>
        <FooterLink href="/contact-us" variant="f16">
          Contact Us
        </FooterLink> */}
      </Stack>

      {/* <Typography variant="body2">
        © {new Date().getFullYear()} Trendora Finds. All rights reserved.
      </Typography> */}
    </FooterContainer>
  );
};

export default Footer;
