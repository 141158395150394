import { styled, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
}));

const StyledLogo = styled(Typography)(({ theme }) => ({
  fontFamily: "Shrikhand",
  fontSize: "1.5rem",
  color: theme.palette.common.black,
  textDecoration: "none",
  textAlign: "left",
  [theme.breakpoints.down("md")]: {
    fontSize: "1rem",
  },
}));

const Logo = () => {
  return (
    <StyledLogo component={StyledLink} to="/">
      Gradient Generator
    </StyledLogo>
  );
};

export default Logo;
